<template>
    <div class="table">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 拼团订单</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-input v-model="select_word" placeholder="请输入订单号、用户昵称、手机号码" class="handle-input mr10"  @keyup.enter.native="searchEnterFun"></el-input>
                <el-button type="primary" icon="search" @click="search" class="mr10">搜索</el-button>
                <el-date-picker
                        class="mr10"
                        v-model="value2"
                        type="datetimerange"
                        format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="pickerOptions"
                        @change="changeTime"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                </el-date-picker>
<!--                <el-select v-model="selectedStatus" class="mr10" @change="change">
                    <el-option
                            v-for="item in statusList"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id">{{item.title}}
                    </el-option>
                </el-select> -->
<!--                <el-select v-if="isShowSelect" v-model="selectedCid" class="mr10" @change="change">
                    <el-option
                            v-for="item in clist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">{{item.name}}
                    </el-option>
                </el-select> -->
<!--                <export-excel :apiUrl="apiUrl" :params="params" style="float: right" :filename="filename" :tHeader="tHeader" :tValue="tValue"></export-excel> -->
                <!--<el-button type="primary" style="float: right" @click="handleEdit(undefined, undefined, 1)">添加</el-button>-->
            </div>
            <el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
                <el-table-column prop="ordernumber" align="center" label="订单号" width="200">
                </el-table-column>
                <el-table-column prop="nickname" align="center" label="昵称">
                </el-table-column>
                <el-table-column prop="name" align="center" label="团购商品">
                </el-table-column>
                <el-table-column prop="groupprice2" align="center" label="团购价">
                </el-table-column>
                <el-table-column prop="group_num" align="center" label="开团人数">
                </el-table-column>
                <el-table-column prop="already_group_num" align="center" label="已团人数">
                </el-table-column>
                <el-table-column prop="groupenddatetime" align="center" label="截至时间">
                </el-table-column>

                <el-table-column prop="datetime" label="下单时间" align="center" sortable>
                </el-table-column>
                <el-table-column prop="status" align="center" label="团购状态">
                    <template slot-scope="scope">
                        <div v-if="scope.row.status==1" style="color:#409EFF">拼团中</div>
                        <div v-else-if="scope.row.status==2" style="color:#008000">已完成</div>
                    </template>
                </el-table-column>
                <el-table-column prop="cname" align="center" label="咨询姓名">
                </el-table-column>

                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row, 2)">查看详情</el-button>
                        <!--<el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
                </el-pagination>
            </div>
        </div>

        <!-- 弹出框 -->
        <el-dialog title="查看" v-loading="loading" :visible.sync="editVisible" width="70%">
            <el-form ref="form" :rules="rules" :model="form" label-width="130px">
                <el-form-item label="咨询姓名">
                    <el-input v-model="form.cname"  style="width:400px" :disabled="true"></el-input>
                </el-form-item>
				<el-form-item label="发起者">
				    <el-input v-model="form.nickname"  style="width:400px" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="联系方式">
				    <el-input v-model="form.tel" style="width:400px" :disabled="true"></el-input>
				</el-form-item>
                <el-form-item label="团购商品">
                    <el-input v-model="form.name" :disabled="true" style="width:400px"></el-input>
                </el-form-item>
                <el-form-item label="团购价">
                    <el-input v-model="form.groupprice2" style="width:400px" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="拼单人数">
                    <el-input v-model="form.group_num" style="width:400px" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="参团人数">
                    <el-input v-model="form.already_group_num" style="width:400px" :disabled="true"></el-input>
                </el-form-item>
				<el-form-item label="开团时间">
				    <el-input v-model="form.datetime" style="width:400px" :disabled="true"></el-input>
				</el-form-item>
                <el-form-item label="拼团状态">
                    <span v-if="form.status==1" style="color:#409EFF; border: 1px solid #409EFF; padding: 2px 5px; border-radius: 5px">拼团中</span>
                    <span v-else-if="form.status==2" style="color:#008000; border: 1px solid #008000; padding: 2px 5px; border-radius: 5px">已完成</span>
                </el-form-item>
                <el-form-item label="拼团明细">
                    <el-table :data="form.detailList" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
                        <el-table-column prop="ordernumber" align="center" label="订单号"></el-table-column>
                        <el-table-column prop="nickname" align="center" label="会员名称">
                        </el-table-column>
                        <el-table-column prop="tel" align="center" label="会员电话">
                        </el-table-column>
                        <el-table-column prop="status" align="center" label="支付状态">

							<template slot-scope="scope">
								<div v-if="scope.row.status==1" style="color:#EDA200">未支付</div>
								<div v-else-if="scope.row.status==2" style="color:#008000">已支付</div>
								<div v-else-if="scope.row.status==3" style="color:#409EFF">已取消</div>
								<div v-else-if="scope.row.status==6" style="color:#008000">已完成</div>
								<div v-else-if="scope.row.status==4" style="color:#FF0000">退款中</div>
								<div v-else-if="scope.row.status==5" style="color:#FF0000">已退款</div>
							</template>
						</el-table-column>
                        <el-table-column prop="datetime" align="center" label="下单时间">
                        </el-table-column>
                    </el-table>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">关 闭</el-button>

<!--                <el-button type="primary" @click="saveEdit('form')">确 定</el-button> -->
            </span>
        </el-dialog>

        <!-- 删除提示框 -->
<!--        <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false">取 消</el-button>
                <el-button type="primary" @click="deleteRow">确 定</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>

<script>
    import  ExportExcel from '.././utils/index';
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import {quillEditor, Quill} from 'vue-quill-editor'
    import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
    Quill.register('modules/ImageExtend', ImageExtend)
    export default {
        name: 'basetable',
        components: {
            quillEditor,
            ExportExcel
        },
        data() {
            return {
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近半年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: [],
                url: './vuetable.json',
                tableData: [],
                cur_page: 1,  //当前页
                number: 10,  //每页显示条数
                sumPage:10,  //总页数
                multipleSelection: [],
                select_cate: '',
                select_word: '',
                del_list: [],
                is_search: false,
                editVisible: false,
                delVisible: false,
                form: {
                    id: '',
                    ordernumber: '',
                    nickname: '',
                    name: '',
                    groupprice2:'',
                    tel: '',
                    remarks: '',
                    sumprice: '',
                    status: '',
                    ordertype: '',
                    detailList: [],
                    group_num: '',
                    already_group_num: '',
                    datetime: '',
                },
                idx: -1,
                dialogVisible: false,
                AddOrSave:'',  //1表示添加，2表示更新
                statusList:[
                    { id: 0, title: "全部"},
                    { id: 1, title: "拼团中"},
                    { id: 2, title: "已完成"},
                ],
                clist: [  //咨询列表
                    { id: 0, name: "所有咨询"}
                ],
                selectedCid: 0,  //当前选中的咨询列表
                selectedStatus:0,
                rules: {

                },
                dialogImageUrl: '',
                isShowBigImg: false,
                caseList:[],  //日记分类列表
                // 富文本框参数设置
                editorOption: {
                    modules: {
                        ImageExtend: {
                            loading: true,
                            name: 'image',
                            action: this.$api.uploadUrl+"/Images/uploadEditorImage",
                            response: (res) => {
                                return res.data
                            }
                        },
                        toolbar: {
                            container: container,
                            handlers: {
                                'image': function () {
                                    QuillWatch.emit(this.quill.id)
                                }
                            }
                        }
                    }
                },
                //规格
                inputVisible: false,
                inputValue: '',
                loading:false, //加载中
                isShowSelect: false,  //是否展示选择姓名下拉框
                tHeader:['ID', '订单号', '用户昵称', '联系电话', '备注', '商品名称', '支付金额', '订单状态', '订单类型', "下单时间", '咨询姓名'],
                tValue:['id', 'ordernumber', 'username', 'tel', 'remarks', 'shopName','payprice','status', 'ordertype', 'datetime', 'cname'],
                filename:'订单数据',
                apiUrl: "GroupBuy/exportExcel",
                params: null,
            }

        },
        created() {
            this.params={
                select_word: this.select_word,
                status: this.selectedStatus,
                cid: this.selectedCid,
                startTime: this.value2 ? this.value2[0] : null,
                endTime: this.value2 ? this.value2[1] : null,
            };
            this.getData();
            //this.getCardList();  //获取所有咨询列表
            const userInfo=JSON.parse(localStorage.getItem('userInfo'));
            if(userInfo.cid){
                this.isShowSelect=false;
            }else{
                this.isShowSelect=true;
            }
        },
        computed: {
            data() {
                return this.tableData.filter((d) => {
                    let is_del = false;
                    for (let i = 0; i < this.del_list.length; i++) {
                        if (d.name === this.del_list[i].name) {
                            is_del = true;
                            break;
                        }
                    }
                    return d;
                })
            }
        },
        methods: {
            onEditorChange({ editor, html, text }) {
                this.form.details = html;
            },
            //按下回车键发起搜索
            searchEnterFun:function(e){
                var keyCode = window.event? e.keyCode:e.which;
                if(keyCode == 13){
                    this.search();
                }
            },
            //确认时间时发起搜索
            changeTime(){
                this.getData();
            },

            // 分页导航
            handleCurrentChange(val) {
                this.cur_page = val;
                this.getData();
            },
            // 获取 easy-mock 的模拟数据
            getData() {
				
				this.params={
				    select_word: this.select_word,
				    status: this.selectedStatus,
				    cid: this.selectedCid,
				    startTime: this.value2 ? this.value2[0] : null,
				    endTime: this.value2 ? this.value2[1] : null,
				};				

                //不传参数要写null
                var params=this.$qs.stringify({
                    select_word: this.select_word,
                    pageIndex: this.cur_page,
                    number: this.number,
                    status: this.selectedStatus,
                    cid: this.selectedCid,
                    startTime: this.value2 ? this.value2[0] : null,
                    endTime: this.value2 ? this.value2[1] : null,
                });
                // console.log(params);
                this.$api.post('GroupBuy/getGroupList', params, res => {
                    this.tableData = res.data.list;
                    this.sumPage = res.data.sumPage*10;
                    this.cur_page = res.data.currentPage;
                    console.log(res);
                }, err => {
                    this.tableData = [];
                    this.sumPage = 1*10;
                    this.cur_page = 1;
                    this.$message.error(err.msg);
                });
                // this.$axios.post(this.url, {
                //     page: this.cur_page
                // }).then((res) => {
                //     this.tableData = res.data.list;
                //     console.log(this.tableData);
                // })
            },
            search() {
                this.is_search = true;
                this.selectedStatus = 0;
                this.getData();
            },
            formatter(row, column) {
                return row.url;
            },
            filterTag(value, row) {
                return row.tag === value;
            },
            handleEdit(index, row, status) {
                this.AddOrSave=status;
                //如果是添加则把form清空
                if(status==1){
                    this.form = {
                        id: null,
                        ordernumber: null,
                        nickname: null,
                        name: null,
                        groupprice2: null,
                        tel: null,
                        remarks: null,
                        sumprice: null,
                        status: null,
                        ordertype: null,
                        detailList: [],
                        group_num: null,
                        already_group_num: null,
                        datetime: null,
                    };
                }
                if(index!=undefined && row!=undefined){
                    this.idx = index;
                    const item = this.tableData[index];
					console.log(item.nickname);
                    this.form = {
                        id: item.id,
                        ordernumber: item.ordernumber,
                        nickname: item.nickname,
                        name: item.name,
                        groupprice2: item.groupprice2,
                        tel: item.tel,
                        remarks: item.remarks,
                        sumprice: item.sumprice,
                        status: item.status,
                        ordertype: item.ordertype,
                        cname: item.cname,
                        detailList: item.detailList,
                        group_num: item.group_num,
                        already_group_num: item.already_group_num,
                        datetime: item.datetime,
                    };
                }
                this.editVisible = true;
                console.log(this.form);
            },
            handleDelete(index, row) {
                this.idx = index;
                this.form = row;
                this.delVisible = true;
            },
            delAll() {
                const length = this.multipleSelection.length;
                let str = '';
                this.del_list = this.del_list.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].b_title + ' ';
                }
                console.log(this.del_list);
                // this.$message.error('删除了' + str);
                // this.multipleSelection = [];
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },

            //获取咨询列表
            getCardList(){
                this.$api.post('ShopOrder/getCardList', null, res => {
                    this.clist=this.clist.concat(res.data);
                }, err => {

                });
            },
            //将转移符号替换为html
            escapeStringHTML(str) {
                if(str){
                    str = str.replace(/&lt;/g,'<');
                    str = str.replace(/&gt;/g,'>');
                    str = str.replace(/&quot;/g,'"');
                }
                return str;
            },
            submit(){
                let str=this.escapeStringHTML(this.form.details);
                console.log(str);
            },
            change(){
                // console.log(this.selectedStatus);
                this.getData();
            },
            //完成订单
            completeOrder(){
                this.$confirm('确认要完成此订单嘛?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var params=this.$qs.stringify({
                        id: this.form.id
                    });
                    console.log(params);
                    this.$api.post('GroupBuy/complete', params, res => {
                        console.log(res);
                        if(res.success){
                            this.form.status=6;
                        }
                        this.$message.success(res.msg);
                        this.getData();
                    }, err => {
                        console.log(err);
                        this.$message.error(err);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消完成订单'
                    });
                });
            },
        }
    }

</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .del-dialog-cnt{
        font-size: 16px;
        text-align: center
    }
    .table{
        width: 100%;
        font-size: 14px;
    }
    .red{
        color: #ff0000;
    }
    .mr10{
        margin-right: 10px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        /*height: 100%;*/
        display: block;
    }
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 30px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 110px;
        margin-left: 10px;
        vertical-align: bottom;
    }
    .ql-snow .ql-editor img{
        vertical-align: top !important;
    }
</style>
